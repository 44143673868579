<template>
  <div id="app">
    
    <router-view/>
  </div>
</template>

<script>
import M from 'materialize-css'

export default {
  mounted () {
    M.AutoInit()
  }
}

</script>



<style>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap');

body {
  margin: 0px;
}

#app {
  font-family: Comfortaa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
}

</style>
