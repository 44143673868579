<template>
  <div class="home">

    <div class="newpage">  
      
      <div class="page1Content">
        <img class="logo" src="../assets/logo.png">
        <h5 class="landingMessage">Our all new digital pet medical care is getting ready for take off! <span id="rocketEmoji"> &#x1F680; </span></h5> 
        <!-- <h2>&#x1F389;</h2> -->
        <h5 class="landingMessage">Drop your email below and we'll notify you when we are ready!</h5>
        <form novalidate @submit.prevent="submitPressed" id="emailForm" ref="emailForm"> 
          <div class="row container">
            <div class="input-field col s12">
              <input id="email" type="email" v-model="email">
              <label for="email">Email</label>
              <button class="waves-effect waves-light btn"><i class="material-icons right">send</i>Get Notified!</button>
               <!-- modal-trigger" data-target="modal1" -->
            </div>
          </div>
        </form> 
        <!-- <img class="doggy" src="../assets/LandingPage4.png"> -->
        <p><b>Juno, Powered by AI.</b></p> 
        <a v-on:click="terms" class="modal-trigger termsLink" href="#modal3">Terms & Conditions</a>
        
      </div>

      <div>
        <img class="rot1" src="../assets/firstScreen_left.png">
        <img class="rot2" src="../assets/home_right.png">
      </div>
    </div>
 
   

  <!-- Modal Success Structure -->
 
  <div id="modal1" class="modal" ref="YayModal">
    <div class="modal-content">
      <h4> &#x1F389; YAY!!! &#x1F389;</h4>
      <p>Thank you for trusting us with your email. We will let you know when we are ready to help you look after your furr babies.</p>
    </div>
    <div class="modal-footer">
      <a href="#" class="modal-close waves-effect waves-light btn-flat">Done</a>
    </div>
  </div>

   <!-- Modal Fail Structure -->
 
  <div id="modal2" class="modal" ref="AahModal">
    <div class="modal-content">
      <h4> &#x1F625; Aaah!!! &#x1F625; </h4>
      <p>Please enter a valid email to submit your details.</p>
    </div>
    <div class="modal-footer">
      <a href="#" class="modal-close waves-effect waves-light btn-flat">Okay, lets try again</a>
    </div>
  </div>

    <!-- Modal Terms and Conditions Structure -->
 
  <div id="modal3" class="modal" ref="LegalModal">
    <div class="modal-content">
      <h4>Terms & Conditions</h4>
      <h5>Introduction</h5>
      <p>Juno Labs (Pty) Ltd is a registered company in South Africa with registration number: 2020/227786/07. These terms and conditions are relevant to our pre-launch landing site. 
        <br><br> By submitting your email address on this website you consent to receiving email notification from us when our platform is up and running. 
        <br><br>If you have any other questions and would like to contact us. We invite you to email us at: <b>support@juno.pet</b>.
        <br><br>Also, if you are a vet or work at a veterinary clinic, please reach out to us. We would love to work with you to make the best products for both our customers.
      </p>
      <p> </p>
      <h4>Privacy Policy</h4>
      <h5>Introduction</h5>
      <p>At Juno Labs, we take your privacy very seriously. We understand the importance of keeping your personal details safe and have taken all the precautions to ensure we do this to the best of our abilities. </p>
      <h5>Data Storage</h5>
      <p>Your email address submitted on the pre-launch landing website will be stored in a secure cloud database using Google Cloud Platforms and Services. Once we are ready for launch we will send you an email, inviting you to come and look at our amazing platform. </p>
      <h5>Data Retention</h5>
      <p>When we go live with our production platform, we will safely and securely delete the database used to store your information from the pre-launch landing page. </p>
      <h5>Disclosure of information</h5>
      <p>Related to your email address shared on our pre-launch landing page, we will not share this information with any third parties. Your email address is collected only in order to notify you once our platform is ready. </p>

      <p>Updated: 2021/01/10</p>
    </div>
    <div class="modal-footer">
      <a href="#" class="modal-close waves-effect waves-light btn-flat">Done</a>
    </div>
  </div>

  </div>
</template>

<script>
import M from 'materialize-css'
const fb = require('../firebaseConfig.js')
// import * as fb from '../firebaseConfig.js'

export default {
    data() {
      return {
        email: null 
      }
    },
    methods: {


      async submitPressed(){
        
        let reg = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        let form = this.$refs.emailForm;
        let failInstance = M.Modal.getInstance(this.$refs.AahModal);
        let instance = M.Modal.getInstance(this.$refs.YayModal);
        let createdDate = new Date(Date.now());
        if(!reg.test(this.email))
        { 
          failInstance.open();
        }
        else if(this.email == null || this.email == '') 
        {
          failInstance.open();
        }
        else
        {
          fb.db.collection('users').add({
          email: this.email,
          created_date: createdDate
          }).then(() => { 
            instance.open();
          }).catch((error) => {
            const errorMessage = error.message;
            console.log(errorMessage);
          }).finally(() => { 
            this.email = '';
            form.reset();
          });
        }

          
      },
      terms() {
        let legalInstance = M.Modal.getInstance(this.$refs.LegalModal);
        legalInstance.open();
      }
    }
}

</script>


<style>


/* medium to large monitor display */
@media only screen and (max-width: 6000px) {
.home {
  max-width: 1400px !important;
  margin: 0 auto;
  margin-top: 4%;
}
.newpage {
  float: left;
  width: 100%;
  position: relative;
  
}
.page1Content {
  width: 50%;
  position: relative;
  margin-left: auto;
  margin-right: auto; 
}
.logo {
  margin-top: 5%;
  width: 40%;
}
.landingMessage {
  margin-top: 0px;
}
.btn {
  background-color: #3F4664 !important;
}
.rot1 {
  width: 35%;
  position: absolute;
  left: 2%;
  bottom: -190px;
  z-index: -1;
}
.rot2 {
  width: 30%;
  position: absolute;
  right: 5%;
  bottom: -165px;
}
#emailForm{
  z-index: 1;
} 
#rocketEmoji {
  font-size: 120%;
}
/* .doggy {
  width: 30%;
} */
.input-field input:focus + label {
   color: #f5487f !important;
 }

.input-field input:focus {
   border-bottom: 1px solid #f5487f !important;
   box-shadow: 0 1px 0 0 #f5487f !important;
 }
.termsLink {
  color:  #f5487f !important;
}
}

/* This is for mobile device */

@media only screen and (max-width: 600px) {
  .newpage {
  float: left;
  width: 100%;
  position: relative;
  
}
.page1Content {
  width: 70%;
  position: relative;
  margin-left: auto;
  margin-right: auto; 
  margin-bottom: 0%;
}
.logo {
  margin-top: 5%;
  width: 90%;
}
.landingMessage {
  margin-top: 0px;
}
.btn {
  background-color: #3F4664 !important;
}
.rot1 {
  width: 90%;
  position: relative;
  margin-top: 5%;
  bottom: 0%;
}
.rot2 {
  width: 90%;
  position: relative;
  bottom: 0%;
}
#emailForm{
  z-index: 1;
} 
#rocketEmoji {
  font-size: 120%;
}
/* .doggy {
  width: 30%;
} */
.input-field input:focus + label {
   color: #f5487f !important;
 }

.input-field input:focus {
   border-bottom: 1px solid #f5487f !important;
   box-shadow: 0 1px 0 0 #f5487f !important;
 }
 .termsLink {
  color:  #f5487f !important;
  padding-bottom: 50px;;
}
}


</style>
