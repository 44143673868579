import Vue from 'vue'
import App from './App.vue'
import router from './router'
//const fb = require('./firebaseConfig.js')
require('./firebaseConfig.js')
import 'materialize-css/dist/css/materialize.min.css'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
