import firebase from 'firebase/app'
import "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyCT2N5kUJyb4uSCD12zOvWS3aO5BWGzh1o",
    authDomain: "juno-pet-web.firebaseapp.com",
    projectId: "juno-pet-web",
    storageBucket: "juno-pet-web.appspot.com",
    messagingSenderId: "1007918180595",
    appId: "1:1007918180595:web:2cf0b72a9ce3ab14c46f86",
    measurementId: "G-X03GLP33F8"
  };

// Initialize Firebase

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()

export {
    db
}